@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
body {
    font-family: 'Roboto', sans-serif;
    margin-top: 0px;
    height: calc(100vh - 10px);
    background-color: #fafafa;
}
#root {
    height: calc(100vh - 10px);
}

/** SCROLL STYLE SECTION */
/* width */
::-webkit-scrollbar {
    height: 9px;
    width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #e7e7e7;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}
