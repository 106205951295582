
.container {
    width: 100px !important;
    height: 100px !important;
  }

.container:hover {
    opacity: 0.5;
}
.overlay {
    opacity: 0;
  }
  
.container:hover .overlay {
    opacity: 1;
}

.icon {
    color: white !important;
    width: 35px !important;
    height: 35px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}